import UserModel from '@/views/service/user/model/UserModel';
import router from '@/router';
import { GET_REQUEST } from '@/services/http';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import apiPath from '@/services/apiPath';

export default class TermDetailViewModel {
  constructor() {
    this.model = {};
    this.isModify = false;
    this.userBoardData = {
      CARD:{
        boardData : {
          title:'등록된 카드 내역',
          drawDataList: [
            {
              title:'실물카드번호',
              width:'170px',
              value:'card_number',
              isAlignLeft: true
            },
            {
              title:'모바일카드번호',
              width:'156px',
              value:'mobile_card_number',
              isAlignLeft: true
            },
            {
              title:'카드상태',
              width:'',
              value:'card_status',
              statusSelect:{
                dataList:'card_status',
              },
              isAlignLeft: true
            },
            {
              title:'상태태변경일시',
              width:'164px',
              value:'updated_at',
              filter:{
                name:'convertDateFormat',
                value:'YYYY-MM-DD HH:mm:ss'
              },
              isAlignLeft: true
            },
          ],
          option:{
            isTotal: true,
            rowIdValue: 'card_number'
          }
        },
        searchParams: {
          page:0,
          pageSize:10,
          direction: 'ASC'
        },
        paginationData: {
          totalCount:2,
          totalPage:1
        }
      },
      CARD_APPLY:{
        boardData : {
          title:'카드 신청 내역',
          drawDataList: [
            {
              title:'신청서 ID',
              width:'94px',
              value:'card_delivery_uid',
              isAlignLeft: true
            },
            {
              title:'신청 부모 이름',
              width:'110px',
              value:'application_user_name',
              isAlignLeft: true
            },
            {
              title:'처리상태',
              width:'',
              value:'status',
              statusSelect:{
                dataList:'card_apply_status',
              },
              isAlignLeft: true
            },
            {
              title:'신청일시',
              width:'164px',
              value:'application_at',
              filter:{
                name:'convertDateFormat',
                value:'YYYY-MM-DD HH:mm:ss'
              },
              isAlignLeft: true
            },
          ],
          option:{
            isTotal: true,
            rowIdValue: 'card_delivery_uid'
          }
        },
        searchParams: {
          page:0,
          pageSize:10,
          direction: 'ASC'
        },
        paginationData: {
          totalCount:1,
          totalPage:1
        }
      },
      CS:{
        boardData : {
          title:'CS 응대 내역',
          drawDataList: [
            {
              title:'인입일',
              width:'104px',
              value:'cearted_at',
              filter:{
                name:'convertDateFormat',
                value:'YYYY-MM-DD'
              }
            },
            {
              title:'문의내용',
              value:'title',
              isEllip: true,
              isAlignLeft: true
            },
            {
              title:'CS 담당자',
              width:'80px',
              value:'cs_manager_name'
            },
            {
              title:'처리상태',
              width:'190px',
              value:'status',
              statusSelect:{
                dataList:'common_cs_status',
              }
            },
            {
              title:'완료일',
              width:'104px',
              value:'complete_at',
              emptyValueText:'-',
              filter:{
                name:'convertDateFormat',
                value:'YYYY-MM-DD'
              },
            },
          ],
          option:{
            isTotal: true,
          }
        },
        searchParams: {
          page:0,
          pageSize:10,
          direction: 'ASC'
        },
        paginationData: {
          totalCount:1,
          totalPage:1
        }
      }
    }
  }

  init(id){
    this.model = new UserModel();
    if(id){
      this.getUserDetail(id)
    }
  }

  getUserDetail(id){
    const path = `${apiPath.USER_DETAIL.format(id)}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      const isChild = resultData.role === 'CHILD';
      this.userBoardData.CARD_APPLY.boardData.title = isChild ? '카드 신청 내역' : '자녀 카드 신청 내역 (대리)'
      this.model.setData(resultData);
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}