export default class UserModel {
  constructor(){
    this.objRaw = {};
    this.accountId = '';
    this.role = '';
    this.role_detail = '';
    this.is_family_leader = true;
    this.is_under_fourteen = true;
    this.legal_representation_id = '';
    this.legal_representation_update_at = '';
    this.name = '';
    this.status = '';
    this.status_update_at = '';
    this.registeredAt = '';
    this.phone_number = '';
    this.email = '';
    this.member_list = {};
    this.walletAmount = null;
    this.marketing_consent = true;
    this.marketing_consent_at = '';
    this.cs_list = [];
  }
  setData(obj){
    if( obj ) this.objRaw = obj;
    const { accountId, role, is_family_leader, is_under_fourteen, legal_representation_id, legal_representation_update_at, name, status, status_update_at, registeredAt, phone_number, email, member_list, walletAmount, card_list, card_apply_list, marketing_consent, marketing_consent_at, cs_list } = obj;
    this.accountId = accountId;
    this.role = role;
    // if(role === 'CHILD' && is_under_fourteen){
    //   this.role_detail = 'CHILD_UNDER_FOURTEEN'
    // }else{
    //   this.role_detail = role
    // }
    
    this.is_family_leader = is_family_leader;
    this.is_under_fourteen = is_under_fourteen;
    this.legal_representation_id = legal_representation_id;
    this.legal_representation_update_at = legal_representation_update_at;
    this.name = name;
    this.status = status;
    this.status_update_at = status_update_at;
    this.registeredAt = registeredAt;
    this.phone_number = phone_number;
    this.email = email;
    this.member_list = member_list || {};
    this.walletAmount = walletAmount;
    this.card_list = card_list;
    this.card_apply_list = card_apply_list;
    this.marketing_consent = marketing_consent;
    this.marketing_consent_at = marketing_consent_at;
    this.cs_list = cs_list || [];
  }
}